<template>
    <div>
       <div class="space-left">
         <h4>{{standardsNameVal}}</h4>
       </div>
        <template>
            <Tree type="table" :node="allStandards" @treeData="recursion"></Tree>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BSidebar, VBToggle, BFormCheckbox, BFormTags, BButton, BCollapse ,BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Tree from './Tree.vue'
export default {
    name: "Treelist",
  data: () => ({
   standarID: '',
   familyDetails: [],
   familyId: '',
   familyAganistData: [],
   show: {},
   allStandards:[],
   standardsNameVal:''
  }),
components: {
    BFormTextarea,
    BSidebar,
    BFormCheckbox,
    vSelect,
    BFormTags,
    BButton,
    BCollapse,
    BCard,
    Tree,
    Tree
},
  directives: {
    'b-toggle': VBToggle,
  },
props: {

},
mounted(){
    this.standarID = this.$route.params.id
    this.getStandardsFamily()
    this.getStandard(this.standarID)
},

methods: {
    tableListFamily () {
        this.$root.$emit('tableFamilylist')
    },
    recursion(data) {
        const index = data.e.target.dataset.index
        let controlName;
        let block = false
        let url = process.env.VUE_APP_ONECSF_API_URL;
        if (Object.keys(data.value).includes('subcontrols')) {
            data.value.subcontrols.forEach(ele => {
                ele.type = 'Subcontrol'
            });
            data.value.childType = 'Subcontrol'
            Vue.set(data.value, 'child', data.value.subcontrols);
            block = true
        } else if (Object.keys(data.value).includes('control_family_id') && Object.keys(data.value).includes('standard_id')) {
            url += `/controls`
            if (data.value.type == 'Control') {
                data.value.childType = 'Sub control'
            } else {
                data.value.childType = 'Control'
            }
        } else if (Object.keys(data.value).includes('standard_id') && !Object.keys(data.value).includes('control_family_id')) {
            url += `/cl_families`
            
            if (!data.value.type) {
                data.value.childType = 'Controls / Group'
            }

        }
        console.log(url +'url')
        console.log(block + 'block')
        if (!block) {
            console.log("inside 86")
            const options = {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                },
                url: url + `/${data.value._id}`,

            };
            this.$http(options)
                .then((res) => {
                    console.log(res.data.data + 'xyz')
                    if (!res.data.data.type ) {
                        data.value.childType = 'Control / Group'
                    } else if (res.data.data.type == 'Group') {
                        data.value.childType = 'Group'
                    } else if (res.data.data.type == 'Control') {
                        data.value.childType = 'Control'
                    } else {
                        data.value.childType = 'Subcontrol'
                    }
                    this.bindValue(res.data.data, index)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    bindValue(val, index) {
        const splitIndex = index.split('_');
        let result = {}
        for (let i = 0; i < splitIndex.length; i++) {
            if (i == 0) {
                result = this.allStandards[splitIndex[i]]
            } else {
                result = result.child[splitIndex[i]]
            }
        }
        if (!Array.isArray(val)) {
            let duplicate = []
            duplicate = result.child
            duplicate.push(val)
            val = duplicate
        }
        Vue.set(result, 'child', val);
    },
    getStandardsFamily() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/cl_families?standard_id=${this.standarID}`,
      };
      this.$http(options)
        .then((res) => {
            this.allStandards = res.data.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStandard(id) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${id}`,
        };
        this.$http(options)
            .then((res) => {
                this.standardsNameVal = res.data.data.standard.name
                this.allStandards = res.data.data.data
            })
            .catch((err) => {
                console.log(err);
            });
    },
},

}
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
.header-family {
    height: 37px;
    background-color: #5a6268;
    color: #fff;
    font-size: 14px;
    padding: 10px;
}
.full-width{
    width: 100%;
    text-align: left;
}
.title-text {
    font-weight: 600;
    margin-right: 10px;
}
.wrap-text {
    display: flex;
}
.mr-bottom {
    margin-bottom: 15px;
}
.data-wrap {
    background-color: #fff;
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    padding: 20px;
}
.space-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-primary-grey {
    background-color: #5a6268 !important;
    color: #fff
}

</style>
